import * as React from 'react';
import styled from 'styled-components';
import { rhythm } from '../utils/typography';
import { H1 } from '../components/typography/Heading';
import P from '../components/typography/Paragraph';

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${rhythm(24)};
`;

const Unauthorized = () => (
  <Wrapper>
    <H1>Unauthorized? Why?</H1>
    <P>Hey there! It seems like you are not an authorized user and my blog is currently for authorized users only!</P>
    <P>
      There&apos;s nothing particularly interesting or controversial in it, just my thoughts, but we live in an era
      where people love to misunderstand and label those around them for whatever reason. I know you now WANT to read
      even more what&apos;s written in here, but I can guarantee you would find &quot;99%&quot; of
      its content to be at best boring and at worst just irreverent.
    </P>
    <P>
      Simply put, I was forced to make a choice between censoring myself or find a way to not have my content public.
      Since I use my blog as a way to just express my thoughts, the first option is... not an option. And since this is
      a website that is supposed to host my bio and projects, removing my name is not an option either. I could have
      gone with an anonymous blog but then I would have to keep 2 separate identities and I&apos;m against that too.
    </P>
    <P>
      We live in sad times and everybody is guilty of being part of this system. It doesn&apos;t matter what your
      political leanings are, this is hurting everyone, including those who think they benefit from this climate of
      fear. You are probably guilty of it too. I definitely am, bowing to the thought police just for the sake of future
      me, who might be guilty of not conforming today to tomorrow&apos;s orthodoxy.
      Unfortunately, I need to rely on being employable and the last thing I want to do is to cause problems to my
      current or future employers.
    </P>
    <P>
      I&apos;m confident we will get to the point people will be really free to express their opinions, but we&apos;re
      not there yet. That said, if you want to read my blog, feel free to get in touch with me via&nbsp;
      <a href="mailto:admin@sachamorgese.com">email</a>
      . I apologize in advance for any weirdness, but this is a big work in progress.
    </P>
  </Wrapper>
);

export default Unauthorized;
